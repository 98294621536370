<template>
  <div
    :id="`tooltip-target-${child.id}`"
    class="tooltip-wrapper"
  >
    <b-tooltip
      v-if="disabled && disableReason"
      :target="`tooltip-target-${child.id}`"
      triggers="hover"
      :delay="{ show: 100, hide: 50 }"
      variant="secondary"
    >
      {{ disableReason }}
    </b-tooltip>

    <b-link
      class="children__block-card"
      :to="linkTo"
      :disabled="disabled"
    >
      <div class="children__block-card-details">
        <BAvatar
          size="48"
          :src="child.avatar"
          :text="avatarText(child.full_name)"
          class="children__block-avatar"
        />
        <h3>{{ child.full_name }}</h3>
      </div>
    </b-link>
  </div>
</template>

<script>
  import {
    BAvatar, BLink, BTooltip,
  } from 'bootstrap-vue'

  export default {
    name: "ChildItem",
    components: {
      BAvatar,
      BLink,
      BTooltip,
    },
    props: {
      child: {
        type: Object,
        default: () => {}
      },
      linkTo: {
        type: Object,
        default: () => {}
      },
      disabled: {
        type: Boolean,
        default: false
      },
      disableReason: {
        type: String,
        default: () => ''
      },
    },
    methods: {
      avatarText(value){
        if (!value) return ''
        const nameArray = value.split(' ')
        return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
      },
    },
  }
</script>

<style scoped>
</style>