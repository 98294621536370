<template>
  <div class="site__centered"
  >
    <div class="application form">
      <h2 class="application__title">Application Form</h2>
      <h2 class="application__program">Program name: <span>{{ programName }}</span></h2>
      <h4 class="application__subtitle">Select child to register for</h4>
      <div
        v-if="childrenList.length > 0"
      >
        <ChildItem 
          v-for="(child, index) in childrenList"
          :key="index"
          :child="child"
          :disabled="isProgramDisableByGender(child)"
          :disable-reason="`The program is for ${programGender.toLowerCase()} only`"
          @click.native="goToApplicationPage(child)"
        />
      </div>
      <button 
        class="btn btn--color-dark form__button"
        @click="addNewChild"
      >Add New +</button>
    </div>
    <AddNewChildModal 
      :is-show-modal="isShowModal"
      @closeModal="closeModal"
      @updateChildrenList="setChildrenList"
    />
  </div>
</template>

<script>

  import { scrollToTop } from '@/helpers/scrollToTop'
  import ChildItem from '@/views/Children/ChildItem.vue'
  import AddNewChildModal from '@/components/site/AddNewChildModal.vue'
  import useApplicationAdd from '@/views/Application/useApplicationAdd'
  import storeModule from '@/views/Children/childrenStoreModule'
  import store from '@/store'
  import { onUnmounted } from '@vue/composition-api'
  import { prepareProgramRegistrationInfo } from '@core/mixins/prepareProgramRegistrationInfo'

  export default {
    name: "ApplicationSelectChild",
    components: {
      ChildItem,
      AddNewChildModal,
    },
    mixins: [ prepareProgramRegistrationInfo ],
    data() {
      return {
        childrenList: [],
        isShowModal: false,
        programId: this.$router.currentRoute.params.id,
        programName: '',
        programGender: null,
        isLoading: false,
      }
    },
    setup() {
      const INVOICE_APP_STORE_MODULE_NAME = 'app-my-children'

      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
      })

      const  { fetchProgram } = useApplicationAdd()

      return { fetchProgram }
    },
    async created() {
      store.commit('SET_LOADING_STATUS', true)

      await this.fetchProgram(this.programId).then(response => {
        this.programName = response.name
        this.programGender = response.gender
      })
      await this.setChildrenList()
      
      store.commit('SET_LOADING_STATUS', false)
    },
    methods: {
      isProgramDisableByGender(child) {
        const gender = child.extra_user_info ? child.extra_user_info.gender : ''

        return this.checkProgramRegistrationIsDisableByGender(gender, this.programGender)
      },
      goToApplicationPage(child) {
        if (!this.hasApplicationByProgram(child)) {
          this.$router.push({
            name: 'application-create',
            params: { id: this.programId },
            query: { user_id: child.id }
          })
        } else {
          this.$router.push({
            name: 'application-create',
            params: { id: this.programId },
            query: {
              app_id: child.applicationsByProgram[this.programId].id,
              user_id: child.id,
            } 
          })
        }
      },
      addNewChild() {
        scrollToTop()
        this.isShowModal = true
      },
      closeModal() {
        this.isShowModal = false
      },
      async setChildrenList() {
        this.$emit('loading', true)
        await store.dispatch('app-my-children/fetchMyChildrenList')
          .then(response => {
            this.childrenList = response.data.data
          }).finally(() => {
            this.$emit('loading', false)
          })
      },
      hasApplicationByProgram(child){
        return child.applicationsByProgram[this.programId] !== undefined
      }
    },
  }
</script>

<style scoped>
</style>