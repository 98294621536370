<template>
  <div class="toastification">
    <div class="toastification-content">
      <h5
              v-if="title"
              class="mb-0 font-weight-bolder toastification-title"
              :class="`text-${variant}`"
              v-text="title"
      />
      <small
              v-if="text"
              class="d-inline-block text-body"
              v-text="text"
      />
    </div>
    <span
      class="toastification-close-icon"
      @click="$emit('close-toast')"
    >x</span>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}
.toastification-close-icon{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.toastification-content {
  margin-right: 5px;
}

.toastification-title {
  color: inherit;
}
</style>
